// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-blog-templates-article-js": () => import("./../../../src/blog/templates/article.js" /* webpackChunkName: "component---src-blog-templates-article-js" */),
  "component---src-blog-templates-category-js": () => import("./../../../src/blog/templates/category.js" /* webpackChunkName: "component---src-blog-templates-category-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-prestations-templates-detail-page-js": () => import("./../../../src/prestations/templates/detail-page.js" /* webpackChunkName: "component---src-prestations-templates-detail-page-js" */),
  "component---src-prestations-templates-list-page-js": () => import("./../../../src/prestations/templates/list-page.js" /* webpackChunkName: "component---src-prestations-templates-list-page-js" */),
  "component---src-projects-templates-detail-page-js": () => import("./../../../src/projects/templates/detail-page.js" /* webpackChunkName: "component---src-projects-templates-detail-page-js" */),
  "component---src-projects-templates-list-page-js": () => import("./../../../src/projects/templates/list-page.js" /* webpackChunkName: "component---src-projects-templates-list-page-js" */)
}

