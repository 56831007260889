import * as React from "react"
import {Image} from "../image";

const GalleryBlock = (props) => {
    return (
        <div className={"gallery " + props.classes}>
            {props.images.map((v, k) => {
                return (
                    <div key={k} className="gallery-item bs parallax-y"  data-from-y={v.start_y ?? 0} data-to-y={v.end_y ?? 0}>
                        <Image src={v.image} alt={v.alt ?? "Image"}/>
                    </div>
                );
            })}
        </div>
    );
}

export default GalleryBlock;
