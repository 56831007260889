import React, {useEffect, useRef} from "react";
import gsap from "gsap";

export const LeftRightDraggeable = ({children}) => {
    const dragg = useRef();
    const services = useRef();
    useEffect(() => {
        let dragged = false;
        let cursorX = 0;

        dragg.current.addEventListener('mousedown', (e) => {
            dragged = true;
            cursorX = e.clientX;

        })

        dragg.current.addEventListener('mouseup', () => {
            dragged = false;
        })

        dragg.current.addEventListener('mouseout', () => {
            dragged = false;
        })

        dragg.current.addEventListener('touchstart', (e) => {
            cursorX = e.touches[0].clientX;
            dragged = true;
        })

        dragg.current.addEventListener('touchend', () => {
            dragged = false;
        })

        dragg.current.addEventListener('wheel', (e) => {
            e.preventDefault();
            let wheel = e.deltaY <= 0 ? 60 : -60;
            moveScroll(wheel);
        });

        dragg.current.addEventListener('mousemove', (e) => {
            if(dragged) {
                moveScroll(e.clientX - cursorX);
                cursorX = e.clientX;
            }
        })

        dragg.current.addEventListener('touchmove', (e) => {
            if(dragged) {
                moveScroll(e.touches[0].clientX - cursorX);
                cursorX = e.touches[0].clientX;
            }
        })

        function moveScroll(px) {
            let max = Math.max(0, services.current.clientWidth -  dragg.current.clientWidth + 32);
            let x = gsap.getProperty(dragg.current, "x") + px;
            if(x >= 0)
                gsap.to(dragg.current, {duration: 0, x: 0});
            else if(x <= -max)
                gsap.to(dragg.current, {duration: 0, x: -max});
            else
                gsap.to(dragg.current, {duration: 0, x: `+=${px}`});
        }
    }, [services]);

    return (
        <div className={"container"} ref={dragg}>
            <div className={"prestation-services"} ref={services}>
                    {children}
            </div>
        </div>
    )
}
