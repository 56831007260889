import React from "react"

export function Video({ url }) {
    return (
        <div>
            <div className={'video-handler'}>
                <iframe src={url} title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        </div>
    )
}
