import React from "react"   
import throttle from "../utils/throttle";

class EventBinded {
    constructor(el, event_type, event_listener) {
        this.el = el;
        this.event_type = event_type;
        this.event_listener = event_listener;
    }

    bind() { 
        this.el.addEventListener(this.event_type, this.event_listener);
    }

    unbind() {
        this.el.removeEventListener(this.event_type, this.event_listener);
    }
}

class Cursor extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            disableCursor: true,
            xmouse: 0,
            ymouse: 0
        }
        this.x = 0;
        this.y = 0;
        this.dx = 0;
        this.dy = 0;
        this.tx = 0;
        this.ty = 0; 
        this.cursor = null;
        this.cursor_pointer = null;

        this.events = []; 
    }

    lerp(a, b, n) {
        return Math.round((1 - n) * a + b * n);
    }

    isTouchScreen() {  
        try {  
            document.createEvent("TouchEvent");  
            return true;  
        } catch (e) {  
            return false;  
        }  
    }

    checkTouchScreen() { 
        this.setState({disableCursor: this.isTouchScreen()});
    }

    followMouse() {
        requestAnimationFrame(this.followMouse.bind(this));

        let { xmouse, ymouse } = { ...this.state };

        if (!this.x || !this.y) {
            this.x = xmouse;
            this.y = ymouse;
        } else {
            this.dx = (xmouse - this.x) * 0.125;
            this.dy = (ymouse - this.y) * 0.125;
            if (Math.abs(this.dx) + Math.abs(this.dy) < 0.1) {
                this.x = xmouse;
                this.y = ymouse;
            } else {
                this.x += this.dx;
                this.y += this.dy;
            }
        } 
        this.cursor.style.left = this.x + 'px';
        this.cursor.style.top = window.scrollY + this.y + 'px';

        this.cursor_pointer.style.left = xmouse + 'px';
        this.cursor_pointer.style.top = window.scrollY + ymouse + 'px';
    } 

    componentDidMount() {
        if (typeof window !== `undefined`) {
            this.cursor = document.querySelector('.cursor');
            this.cursor_pointer = document.querySelector('.cursor_pointer');
            let timer = 0;
            let timerClick = 0;

            this.followMouse();
            this.checkTouchScreen();
            this.destroyEvents();

            this.events.push(new EventBinded(document, 'mousemove', (e) => {
                this.setState({
                    xmouse: e.clientX || e.pageX,
                    ymouse: e.clientY || e.pageY
                })
                this.cursor.classList.add('is-moving');
                clearInterval(timer);
                timer = setTimeout(() => {
                    this.cursor.classList.remove('is-moving');
                }, 200);
            })); 

            this.events.push(new EventBinded(document, 'click', (e) => { 
                this.cursor_pointer.classList.add('is-click');
                clearInterval(timerClick);
                timerClick = setTimeout(() => {
                    this.cursor_pointer.classList.remove('is-click');
                }, 200);
            })); 
               
            this.events.push(new EventBinded(window, "resize", throttle(this.checkTouchScreen.bind(this), 300, true, true))); 

            this.buildEvents();
        }
    }

    buildEvents() {
        function enterCursor(e) {
            this.cursor.classList.add('is-hover'); 
        }
        function leaveCursor(e) {
            this.cursor.classList.remove('is-hover'); 
        }  
        
    
        let links = document.querySelectorAll("a, button, .menu-opener");
        links.forEach(value => {
            this.events.push(new EventBinded(value, "mouseenter", enterCursor.bind(this))); 
            this.events.push(new EventBinded(value, "mouseleave", leaveCursor.bind(this)));
        }) 

        this.events.forEach(value => value.bind());
    }

    destroyEvents() {
        this.events.forEach(value => value.unbind());
        this.events = [];
    }
 
    componentWillUnmount() { 
        this.destroyEvents();
    }

    render() {  
        return (
            <div>
                <div className="cursor" style={{display: this.state.disableCursor ? "none": "block"}}></div>
                <div className="cursor_pointer" style={{ display: this.state.disableCursor ? "none" : "block" }}></div>
            </div>
        )
    }
}

export default Cursor
