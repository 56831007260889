import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState} from 'react';

export const Image = (props) => {
  let [image, setImage] = useState(null);
  let imageRef = React.createRef();

  const attr =  props.attr || [];
  const classes = props.class || props.className || '';

  const data = useStaticQuery(graphql`
          query {
            images: allFile {
              edges {
                node {
                  relativePath
                  name
                  modifiedTime
                  childImageSharp {
                    fluid(maxWidth: 1440, quality: 80,webpQuality:90, srcSetBreakpoints: [576, 992, 1440]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
    `);

  useEffect(() => {
      const filterNodes = (edges) => {
          return edges.forEach(n => {
              if (n.node.relativePath.includes(props.src)) {
                  setImage(n);
              }
          });
      }
      filterNodes(data.images.edges);
  }, [data, props.src])

  if(image == null)
    return (<div></div>);

  return (
      <div
          className={'lazy-wrapper ' + classes}
          style={{
            height: props.height,
            width: props.width,
          }}
          ref={props.innerRef}
          {...(attr)}
      >
        <Img
            alt={props.alt}
            fluid={{
              ...image.node.childImageSharp.fluid,
              sizes: `(max-width: 576px) 576px, (max-width: 992px) 992px, (max-width: 1440px) 1440px, 1440px`
            }}
            ref={imageRef}
            className="lazy-image"
        />
      </div>
  );
}

