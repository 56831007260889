import * as React from "react"
import { useRef} from "react";

const Sticker = (props) => {
    let tag = useRef();
    return (
        <div className={`sticker-container sticker-container_${props.name}`}>
            <img ref={tag} alt={"Sticker"} className={`sticker sticker_${props.name}`} src={`/images/stickers/${props.name}.svg`}/>
        </div>
    );
}

export default Sticker;
