import * as React from "react"
import { MDXComponents } from "./components";
import {MDXProvider} from "@mdx-js/react"
import { Grain } from './cases/grain';
import {useEffect} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Cursor from "./cursor";
import Header from "./layout/header";
import Footer from "./layout/footer";

const Layout = (props) => {
    const { children, path } = props

    // Container size
    let update = () => {
        const  grid_size = (window.innerWidth <= 1024) ? 40 : 64;
        let container = document.querySelectorAll(' .page-content > .container:not(.ignore-container), .page-content > .section, #hero-project');
        container.forEach(value => {
            value.style.height = null;
            let height = value.clientHeight;
            const styles = window.getComputedStyle(value);
            const margin = parseFloat(styles['marginTop']) +
                parseFloat(styles['marginBottom']);


            value.style.height = `${Math.ceil((height + margin) / grid_size) * grid_size}px`;
        });
    }

    useEffect(() => {
        // GRID ALIGNEMENT
        let document_width = ((Math.abs(document.body.clientWidth - Math.min(document.body.clientWidth, 1400)) / 2) % 64) + 24;
        document.body.style.backgroundPositionX = `${document_width}px`;

        // SCROLL ANIMATION GSAP

        let proxy = { skew: 0 },
            skewSetter = gsap.quickSetter("html", "--skew", "deg"), // fast
            revertSkewSetter = gsap.quickSetter("html", "--skew-negate", "deg"), // fast
            clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

        if (typeof window !== "undefined") {
            gsap.registerPlugin(ScrollTrigger);
            ScrollTrigger.create({
                trigger: document.querySelector("header"),
                start: "top top",
                endTrigger: document.querySelector("footer"),
                end: "bottom bottom",
                onUpdate: (self) => {
                    let skew = clamp(self.getVelocity() / -300);
                    // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
                    if (Math.abs(skew) > Math.abs(proxy.skew)) {
                        proxy.skew = skew;

                        gsap.to(proxy, {
                            skew: 0,
                            duration: 0.8,
                            ease: "power3",
                            overwrite: true,
                            onUpdate: () =>{
                                skewSetter(proxy.skew);
                                revertSkewSetter(-proxy.skew);
                            }
                        });
                    }
                }
            });
        }

        if (typeof window !== `undefined`) {
            window.addEventListener('resize', update);
            window.addEventListener('load', update);
            window.updateGrid = update;
        }
    }, []);

    useEffect(() => {
        update();
    }, [path]);

    if (typeof window !== `undefined`) {
        const appHeight = () => {
            const doc = document.documentElement
            doc.style.setProperty('--app-height', `${window.innerHeight}px`)
        };

        window.addEventListener('resize', appHeight);
        appHeight();
    }

    return(
        <div>
            <Header white={true}/>
            <Cursor path={path}/>
            <main> 
                <MDXProvider components={MDXComponents}>{children}</MDXProvider>
                <div id="pattern"></div>
                <Grain/>
            </main>
            <Footer/>
        </div> 
    );
}

export default Layout;
