import React from "react"
import {Image} from "../image";
import {withPrefix} from "gatsby";

export const FEATURE_SIZE = {
    NORMAL: "normal",
    SMALL: "small",
    LARGE: "large"
}

export function Feature({ title, description = null, image = null, alt= null, size = FEATURE_SIZE.NORMAL}) {
    return (
        <div className={`feature feature_${size}`}>
            <div className={"feature-visual"}>
                {
                    image.match(/(gif)/g) && (
                        <img src={withPrefix(`/images/${image}`)} alt={alt ?? title}/>
                    )
                }
                {
                    !image.match(/(gif)/g) && (
                        <Image src={image} alt={alt ?? title} />
                    )
                }
            </div>
            <div className={"feature-content"}>
                <h4>{title}</h4>
                {description && (
                    <p>{description}</p>
                )}
            </div>
        </div>
    )
}
