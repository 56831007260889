import React from "react";
import LinkFade from "../linkfade";

export function LeaveFragment({to = "revenir aux projets;"}) {
    return (
        <section className="leave-section">
            <div className="inner-section">
                <div className={"big-button"}>
                    <svg width="100%" height="100%" viewBox="0 0 200 200">
                        <path d="M89.5,89.5L22,89.5C16.205,89.5 11.5,94.205 11.5,100C11.5,105.795 16.205,110.5 22,110.5L89.5,110.5L89.5,178C89.5,183.795 94.205,188.5 100,188.5C105.795,188.5 110.5,183.795 110.5,178L110.5,110.5L178,110.5C183.795,110.5 188.5,105.795 188.5,100C188.5,94.205 183.795,89.5 178,89.5L110.5,89.5L110.5,22C110.5,16.205 105.795,11.5 100,11.5C94.205,11.5 89.5,16.205 89.5,22L89.5,89.5Z" fill={"#2E1FFF"}/>
                    </svg>

                    <LinkFade url={"../"}>
                        {to}
                    </LinkFade>
                </div>
            </div>
        </section>
    )
}
