import * as React from "react"
import LogoSvg from "./logo-svg";


const Footer = () => {
    return(
        <footer>
            <div className={"container bg-blue shadow"}>
                <div className={"md:flex space-between items-center"}>
                    <div className={""}>
                        <LogoSvg white={true}/>
                        <div className={"text-white"}>designer & développeur freelance.<br/>Je crée des sites qui émerveillent vos clients 🌻</div>
                    </div>

                    <div className={""}>
                        <div className={"btn-list"}>
                            <a className={"btn-circle btn-secondary btn-icon btn-linkedin"} aria-label="Twitter" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/lorispinna/"> </a>
                            <a className={"btn-circle btn-secondary btn-icon btn-github"} aria-label="Github" target="_blank" rel="noreferrer" href="https://github.com/DevLoris/"> </a>
                            <a className={"btn-circle btn-secondary btn-icon btn-twitter"} aria-label="Twitter" target="_blank" rel="noreferrer" href="https://twitter.com/EpicLoris"> </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
