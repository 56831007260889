import * as React from "react"
import {useState} from "react";
import {useEffect} from "react";
import {gsap} from 'gsap';
import LinkFade from "../linkfade";
import { globalHistory } from '@reach/router'

const Menu = (data) => {
    const [menuOpen, toggleMenu]  = useState(false);


    /*const [contactClicked, toggleContactClicked] = useState(false);

    useEffect(() => {
        if(contactClicked) {

        }
        else {

        }
    }, [contactClicked]);*/

    useEffect(() => {
        return globalHistory.listen(({ action }) => {
            if (action === 'PUSH') toggleMenu(false)
        })
    }, [])

    useEffect(() => {
        if(!menuOpen) {
            let timeline = gsap.timeline();
            //timeline.to('.menu-content nav', {opacity: 0, duration : 0.2});
            timeline.to(".menu-bar_1", {x: 0, rotate: "0"}, "<");
            timeline.to(".menu-bar_2", {x: 0}, "<");
            timeline.to(".menu-bar_3", {x: 0, y: 0, rotate: "0"}, "<");
            timeline.to('.menu-content', {x: "510px"}, "<");
        }
        else {
            let timeline = gsap.timeline();
            timeline.to(".menu-bar_1", {y: 0, x: 10, rotate: "45"});
            timeline.to(".menu-bar_2", {x: 100}, "<");
            timeline.to(".menu-bar_3", {y: 1, x: 5, rotate: "-45"}, "<");
            timeline.to('.menu-content', {x: "0%"}, "<");
            timeline.fromTo('.menu-content nav li', {opacity: 0}, {opacity: 1, stagger: {amount: 1}});
        }
    }, [menuOpen]);

    return(
        <menu id={"menu"}>
            <div className={"menu-opener"}>
                <svg onClick={() => { toggleMenu(!menuOpen); }} xmlns="http://www.w3.org/2000/svg" width="40" height="34" viewBox="0 0 40 34">
                    <g id="Groupe_35" data-name="Groupe 35">
                        <rect className={"menu-bar_1"} width="40" height="7" y={0}   fill="white"/>
                        <rect className={"menu-bar_2"}  width="40" height="6" y={14} fill="white"/>
                        <rect className={"menu-bar_3"} width="40" height="7" y={27} fill="white"/>
                    </g>
                </svg>
            </div>
            <div className={"menu-content"} style={{transform: 'translateX(510px)'}}>
                <nav>
                    <ul>
                        <li>
                            <LinkFade url="/" callback={() => { toggleMenu(false); }}>
                                l'accueil
                            </LinkFade>
                        </li>
                        <li>
                            <LinkFade url="/about/" callback={() => { toggleMenu(false); }}>
                                moi
                            </LinkFade>
                        </li>
                        <li>
                            <LinkFade url="/projects/" callback={() => { toggleMenu(false); }}>
                                mes réals
                            </LinkFade>
                        </li>
                        <li>
                            <LinkFade url="/prestations/" callback={() => { toggleMenu(false); }}>
                                prestations
                            </LinkFade>
                        </li>
                        <li>
                            <LinkFade url="/blog/" callback={() => { toggleMenu(false); }}>
                                blog
                            </LinkFade>
                        </li>
                        <li>
                            <LinkFade url="/contact/" callback={() => { toggleMenu(false); }}>
                                contact
                            </LinkFade>
                        </li>
                    </ul>
                </nav>
                <a className={"mail"} href={"mailto:devloris@gmail.com"}>devloris@gmail.com</a>
            </div>
        </menu>
    )
}

export default Menu;
