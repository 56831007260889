import { Image } from "./image";
import {Slider} from "./cases/slider";
import {LeaveFragment} from "./fragments/leave-fragment";
import PartNumber from "./part-number";
import DevelopmentBlock from "./section/development-block";
import GalleryBlock from "./section/gallery-block";
import ConceptBlock from "./section/concept-block";
import HorizontalGalleryBlock from "./section/horizontal-gallery-block";
import {Badges} from "./cases/badges";
import {Video} from "./cases/video";
import {MarqueeTitle} from "./cases/marquee-title";
import {LeftRightDraggeable} from "./cases/left-right-draggeable";
import {Feature} from "./cases/feature";
import Button from "./button";
import Sticker from "./sticker";

const components = { 
    Image,
    Slider,
    LeaveFragment,
    PartNumber,
    DevelopmentBlock,
    GalleryBlock,
    ConceptBlock,
    Badges,
    Video,
    HorizontalGalleryBlock,
    MarqueeTitle,
    LeftRightDraggeable,
    Button,
    Sticker,
    Feature
};

export const MDXComponents = components;
