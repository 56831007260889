import * as React from "react"
import Logo from "./logo";
import Menu from "./menu";


const Header = ({white}) => {
    return(
        <header>
            <Logo white={white}/>
            <Menu/>
        </header>
    );
}

export default Header;
