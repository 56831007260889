import * as React from "react"
import LinkFade from "./linkfade";

const Button = (props) => {
    if(props.link)
        return (
            <LinkFade url={props.link}>
                <button className="btn btn-animated">
                    <div>
                        <span data-text={props.name}>{props.name}</span>
                    </div>
                </button>
            </LinkFade>
        )
    return (
        <button className="btn btn-animated" onClick={(e) => {
            e.preventDefault();
            props.callback();
        }}>
            <div>
                <span data-text={props.name}>{props.name}</span>
            </div>
        </button>
    );
}

export default Button;
