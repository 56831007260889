import * as React from "react";

export const MarqueeTitle = ({text}) => {
    return (
        <div className={"background-title-container background-title-container__left as-title as-marquee"}>
            <div className={"background-title"}>
                <span>{text}&nbsp;</span>
                <span>{text}&nbsp;</span>
            </div>
        </div>
    )
}
