import * as React from "react"
import {useKeenSlider} from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import {useEffect} from "react";
import {Badges} from "../cases/badges";
import {Image} from "../image";

const animation = { duration: 20000, easing: (t) => t }
export default function HorizontalGalleryBlock({ images, classToAdd, badges =  "assets" }) {
    const [sliderRef, slider] = useKeenSlider({
        initial: 0,
        mode: "free",
        loop: true,
        dragSpeed: 0.5,
        autoplay: true,
        renderMode: "performance",
        breakpoints: {
            '(min-width: 500px)': {
                slides: {
                    spacing: 32,
                    perView: "auto",
                }
            },

        },
        slides: {
            perView: "auto",
            spacing: 16
        },
        created(s) {
            s.moveToIdx(s.track.details.slides.length, true, animation)
        },
        updated(s) {
            s.moveToIdx(s.track.details.abs + s.track.details.slides.length, true, animation)
        },
        animationEnded(s) {
            s.moveToIdx(s.track.details.abs + s.track.details.slides.length, true, animation)
        },
    })

    useEffect(() => {
        let s = slider.current;
        s.container.addEventListener('mouseenter', () => {
            s.animator.stop();
        });
        slider.current.container.addEventListener('mouseleave', () => {
            s.moveToIdx(s.track.details.abs + s.track.details.slides.length, true, animation)
        });
    },  [slider])

    return (
        <>
            <div className={`h-gallery ${classToAdd ?? ""}`}>
                {badges && <Badges name={badges} width={"168"}/>}
                <div ref={sliderRef} className="keen-slider">
                    {
                        images.map((v, i) => {
                            return (
                                <div key={i} className="keen-slider__slide">
                                    {typeof v === "string" && (
                                        <img src={v} alt={"Slider"}/>
                                    )}
                                    {typeof v !== "string" && (
                                        <Image src={v.image} alt={v.alt}/>
                                    )}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    )
}

