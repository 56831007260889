import React from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

export function Slider({ slides }) {
    //const [currentSlide, setCurrentSlide] = React.useState(0)
    const [sliderRef, slider] = useKeenSlider({
        initial: 0,
        loop: true,
        slideChanged(s) {
            //setCurrentSlide(s.track.details.rel)
        },
    })

    return (
        <>
            <div className="navigation-wrapper">
                <div ref={sliderRef} className="keen-slider">
                    {
                        slides.map((v, i) => {
                            return (
                                <div key={i} className="keen-slider__slide">
                                    <img src={v} alt={"Slider"}/>
                                </div>
                            );
                        })
                    }
                </div>
                {slider && (
                    <>
                        <ArrowLeft
                            onClick={(e) => e.stopPropagation() || slider.current.prev()}
                        />
                        <ArrowRight
                            onClick={(e) => e.stopPropagation() || slider.current.next()}
                        />
                    </>
                )}
            </div>
        </>
    )
}



function ArrowLeft(props) {
    return (
        <button
            onClick={props.onClick}
            className={"btn-circle btn-arrow btn-arrow_left"}
            aria-label={"Slide change"}
        >
        </button>
    )
}

function ArrowRight(props) {
    return (
        <button
            onClick={props.onClick}
            className={"btn-circle btn-arrow btn-arrow_right"}
            aria-label={"Slide change"}
        >
        </button>
    )
}
