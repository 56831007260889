import * as React from "react"

const ConceptBlock = (props) => {
    return (
        <section>
            <div className={`inner-section inner-section-big inner-section_larger ${props.classToAdd ?? ``}`}>
                <div className="line-title">
                    <h2>{props.title}</h2>
                </div>
                {props.children}
            </div>
        </section>
    );
}

export default ConceptBlock;
