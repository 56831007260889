import * as React from "react"
import LinkFade from "../linkfade";
import LogoSvg from "./logo-svg";

const Logo = (props) => {
    return (
        <LinkFade url="/" >
            <LogoSvg white={props.white}/>
        </LinkFade>);
}

export default Logo;
