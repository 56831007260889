import * as React from "react"
import {Badges} from "../cases/badges";

const DevelopmentBlock = (props) => {
    return (
        <section className="blue-section dev-block  bs parallax-y" data-from-y="3" data-to-y="-3">
            <Badges name={"dev"} width={96*1.75}/>
            <div className="inner-section ">
                <div className="line-title">
                    <h2>{props.title}</h2>
                </div>
                <div className={"tag-row"}>
                    {props.tags.map((v, k) => {
                        return (
                            <span key={k} className="tag">{v}</span>
                        );
                    })}
                </div>
                {props.children}
            </div>
        </section>
    );
}

export default DevelopmentBlock;
